import Vue from 'vue'
import { Button,Icon,Tab,Tabs } from 'vant'
import { Col, Row } from 'vant';
import { Tabbar, TabbarItem } from 'vant'
import {Grid,GridItem} from 'vant'
import { Search } from 'vant';
import { List } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Image as VanImage } from 'vant';
import { CountDown } from 'vant';
import { AddressList } from 'vant-green';
import { Field } from 'vant';
import { Area } from 'vant';
import {Popup} from 'vant'
import { Picker } from 'vant';
import { DatetimePicker } from 'vant';
import { Toast } from 'vant';
import { Dialog } from 'vant';
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(DatetimePicker);
Vue.use(Picker);
Vue.use(Area)
Vue.use(Popup)
Vue.use(Field);
Vue.use(AddressList);
Vue.use(CountDown);
Vue.use(VanImage);
Vue.use(Cell).use(CellGroup);
Vue.use(List);
Vue.use(Search);
Vue.use(Grid);
Vue.use(GridItem)
Vue.use(Col);
Vue.use(Row);
Vue.use(Button)
Vue.use(Icon)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Tabbar)
Vue.use(TabbarItem)