<template>
  <div>
    <div class="doctorinfo">
      <div class="topinfo">
        <van-image
          round
          width="1.5rem"
          height="1.5rem"
          src="https://img01.yzcdn.cn/vant/cat.jpeg"
        />
        <div style="display: flex; align-items: center; padding-left: 10px">
          <div style="font-size: 20px; color: #fff">张森茂</div>
          <div style="font-size: 16px; color: #fff; margin-left: 10px">
            主治医生
          </div>
        </div>
      </div>
      <div class="a" style="margin-top: 20px">
        <span style="margin-left: 10px; font-size: 16px; color: #fff"
          >简介</span
        >
      </div>
      <div style="font-size: 16px; color: #fff; margin-top: 20px">
        擅长中西医结合治疗痤疮、黄褐斑、面部皮炎、等损容性疾病、湿疹、荨麻疹、神经性皮炎
      </div>
    </div>
    <div class="weeklist">
      <div>全部</div>
      <div style="display: flex">
        
      </div>
    </div>
    <div class="dateinfo">
      <div style="padding: 10px">皮肤科</div>
      <div
        style="width: 100%; height: 1px; background-color: rgb(128, 128, 128)"
      ></div>
      <div
        style="
          padding: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div>
          <div style="display: flex; flex-wrap: wrap">
            <div style="width: 40px">8.02</div>
            <div style="width: 50px">星期三</div>
            <div style="width: 40px">下午</div>
            <div style="width: 40px">当天</div>
          </div>
          <div>专家门诊 15.5元</div>
        </div>
        <van-button round type="info" @click="yh">余号：18</van-button>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "Yyghdoctorinfo",
  data(){
    return{
      
    }
  },
  methods: {
    yh() {
      this.$router.push("/ghtj");
    },
  },
  mounted(){ 
      
  }
};
</script>
  
  <style lang="less" scoped>
.doctorinfo {
  height: 4rem;
  background: rgb(89, 181, 253);
  padding: 20px;
  .topinfo {
    display: flex;
    align-items: center;
  }
  .a::before {
    content: "|";
    color: #fff;
  }
}
.dateinfo {
  width: 90%;
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
  margin-top: 20px;
}
.weeklist {
  font-size: 20px;
  font-weight: 400;
  align-items: center;
  height: 1rem;
  display: flex;

  background: #fff;
}
</style>