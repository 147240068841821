import { registenow,showdn,clickgh,clicktj,refushord } from "@/api/drgh"

const getDefaultState = ()=>{
    return{
        ks_list:[],
        ghinfo:[],
        showghinfo:{},
        tjinfo:{},
        time:0,
    }
    


}
const state = getDefaultState()

const mutations = {
    SET_KSLIST:(state,ks_list)=>{
        state.ks_list = ks_list

    },
    SET_GHINFO:(state,ghinfo)=>{
        state.ghinfo = ghinfo
    },
    SET_SHOWGHINFO:(state,showghinfo)=>{
        state.showghinfo = showghinfo
    },
    SET_TJINFO:(state,tjinfo)=>{
        state.tjinfo = tjinfo
    },
    SET_TIME:(state,time)=>{
        state.time = time

    }


}
const actions = {
    registenow({commit}){
        return new Promise((resolve,reject)=>{
            registenow().then((response)=>{
                commit('SET_KSLIST',response.data)
                console.log(response.data)
                resolve()
            }).catch(()=>{
                reject()
            })
        })
    },
    showdn({commit},data){
        return new Promise((resolve,reject)=>{
            showdn(data).then((response)=>{
                console.log(response)
                commit('SET_GHINFO',response.data)
                resolve()
            }).catch(()=>{
                reject()
            })
        })

    },
    clickgh({commit},data){
        return new Promise((resolve,reject)=>{
            clickgh(data).then((response)=>{
                console.log(response.data)
                commit('SET_SHOWGHINFO',response.data)
                resolve()
            }).catch(()=>{
                reject()
            })
        })
    },
    clicktj({commit},data){
        return new Promise((resolve,reject)=>{
            clicktj(data).then((response)=>{
                console.log(response)
                commit('SET_TJINFO',response.data)
                resolve()
            }).catch((error)=>{
                reject(error)
            })
        })

    },
    refushord({commit},data){
        return new Promise((resolve,reject)=>{
            refushord(data).then((response)=>{
                console.log(response.d_time)
                commit('SET_TIME',response.d_time)
                resolve()
            }).catch(()=>{
                reject()
            })

        })
    }

}
export default{
    namespaced:true,
    state,
    actions,
    mutations
}

