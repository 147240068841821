<template>
  <div class="box">
    <div class="content">
        <router-view style="padding-bottom: 50px;"></router-view>
    </div>
    <van-tabbar v-model="active" class="box_bottom">
      <van-tabbar-item icon="home-o" name="home" @click="enter"
        >首页</van-tabbar-item
      >
      <van-tabbar-item icon="search" name="message" @click="enter"
        >消息</van-tabbar-item
      >
      <van-tabbar-item icon="friends-o" name="zixun" @click="enter"
        >咨询</van-tabbar-item
      >
      <van-tabbar-item icon="setting-o" name="my" @click="enter"
        >我的</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "Layout",
  data() {
    return {
      active: "home",
    };
  },
  mounted(){
    this.login()
  },
  methods: {
    login() {
          this.$wx.ready(() => {
           
            this.$wx.login({
              success: (res) => {
                console.log(res);
              },
            });
          });
        },
    enter() {
      if (this.active == "home") {
        this.$router.push("/home");
      } else if (this.active == "message") {
        this.$router.push("/message");
      } else if (this.active == "zixun") {
        this.$router.push("/zixun");
      } else {
        this.$router.push("/my");
      }
    },
  },
};
</script>

<style lang="less" scoped>
html,body {
  height: 100%;
  background: rgb(245, 245, 245);
}
.box{
  width: 100%;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
  .content{
    flex: 1;
    width: 100%;
    overflow: auto;
  }
  .box_bottom{
    height: 50px;
    width: 100%;
  }

}
</style>