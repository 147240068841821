<template>
  <div>
    <div class="djs">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div style="font-size: 25px; font-weight: 600; color: #fff">
          {{ zfinfo.status}}{{ zfinfo.statu_name }}
        </div>
        <div style="font-size: 20px; color: #fff" v-show="timeshow">
          <div style="display: flex; align-items: center">
            剩余:<van-count-down
              :time="time"
              format=" mm 分 ss 秒"
              style="color: #fff; font-size: 20px"
              @finish = 'finish'
            />
          </div>
        </div>
      </div>
      <div style="font-size: 16px; color: #fff">预约成功</div>
      <div style="font-size: 16px; color: #fff">
        请准时于就诊当天前往医院门诊就诊
      </div>
    </div>
    <div style="padding-bottom: 80px">
      <div class="zfinfo">
        <div class="date" style="text-align: center">
          <span style="font-size: 25px; font-weight: 600">{{
            zfinfo.hz_time
          }}</span>
          <span
            style="
              display: inline-block;
              margin-left: 10px;
              font-size: 16px;
              width: 45px;
              height: 30px;
              background-color: rgb(231, 240, 255);
              line-height: 30px;
              border-radius: 20px;
              font-weight: 600;
              color: rgb(66, 137, 255);
            "
            >{{ zfinfo.h }}号</span
          >
        </div>
        <div style="padding: 10px 20px 20px 20px; font-size: 18px">
          <div class="dateitem">
            <span class="key">就诊医院</span>
            <span class="value" style="color: red">{{ zfinfo.org_name }}</span>
          </div>
          <div class="dateitem">
            <span class="key">就诊科室</span>
            <span>{{ zfinfo.ks_name }}</span>
          </div>
          <div class="dateitem">
            <span class="key">就诊医生</span>
            <span>{{ zfinfo.jz_doctor }}</span>
          </div>
          <div class="dateitem">
            <span class="key">门诊类型</span>
            <span>{{ zfinfo.reg_category_name }}门诊</span>
            <span style="color: red; margin-left: 0.2rem"
              >{{ zfinfo.reg_je }}元</span
            >
          </div>
          <div class="dateitem">
            <span class="key">就诊地点</span>
            <span style="color: red">{{ zfinfo.jz_address }}</span>
          </div>
          <div
            style="
              width: 100%;
              height: 1px;
              background-color: rgb(229, 230, 235);
              margin-top: 10px;
            "
          ></div>
          <div class="dateitem">
            <span class="key" style="letter-spacing: 8px; margin-right: 14px"
              >就诊人</span
            >
            <span>{{ zfinfo.patient_name }}</span>
            <span style="margin-left: 10px">门诊自费</span>
          </div>
          <!-- <div class="dateitem">
            <span class="key">就诊卡号</span>
            <span class="value">{{ zfinfo.idcard }}</span>
          </div> -->
        </div>
      </div>
    </div>
    <div class="ljzf">
      <van-button class="zfbutton"  round color="rgb(64,158,255)"  v-show="ghway"
        >取消预约</van-button
      >
      <van-button class="zfbutton" round color="rgb(64,158,255)" @click="pay"
        >立即支付</van-button
      >
     
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {wx_gzh_pay} from '../../api/weixin'
export default {
  name: "Drghzf",
  data() {
    return {
      time: 0,
      zfinfo: {},
      ghway:"",
      timeshow:true,
    };
  },
  methods: {
    getzfinfo() {
      this.zfinfo = JSON.parse(localStorage.getItem("zfinfo"));
    },
    newinfo(){
      this.$store.dispatch('jzrgl/myy_detail',{openid:this.zfinfo.openid,order_id:this.zfinfo.order_id}).then(()=>{
            this.zfinfo = this.$store.state['jzrgl']['detail']
            localStorage.setItem('zfinfo',this.zfinfo)
           })
    },
    finish(){
      this.newinfo()
      this.timeshow = false

    },
    onBridgeReady() {
      wx_gzh_pay({openid:localStorage.getItem('openid'),trade_no:this.zfinfo.order_id}).then((response) => {
        console.log(response)
        WeixinJSBridge.invoke("getBrandWCPayRequest", {
          appId: "wxcd07cf8a7e2d6e7a", //公众号ID，由商户传入
          timeStamp: response.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: response.nonceStr, //随机串
          package: response.package,
          signType: response.signType, //微信签名方式：
          paySign:response.paySign, //微信签名 
        },res=>{
          if(res.err_msg == "get_brand_wcpay_request:ok"){
           this.newinfo()
           this.timeshow = false
          }else{
           console.log(response)
           this.newinfo()
          }
        });
      });
    },
    pay(){
      if (typeof WeixinJSBridge == "undefined") {
    if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
    } else if (document.attachEvent) {
        document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
    }
} else {
    this.onBridgeReady();
}
      
    },
    sx(){
      this.getzfinfo();
      console.log(this.zfinfo);
      this.$store
        .dispatch("drgh/refushord", { order_id: this.zfinfo.order_id })
        .then(() => {
          console.log(this.zfinfo);
          this.time = this.$store.state["drgh"]["time"] * 1000;
          if(this.time===0){
            this.timeshow = false
          }
          this.newinfo()
        })
        .catch(() => {
          console.log("2222");
        });
    },
  },
  mounted() {
    // this.getzfinfo()
    this.sx();
    this.ghway = this.$route.query.ghway
  },
};
</script>

<style lang="less" scoped>
.djs {
  padding: 20px;
  height: 150px;
  background: #409eff;
}
.zfinfo {
  width: 95%;
  border-radius: 10px;
  background: #fff;
  margin: 0px auto;
  margin-top: -60px;
}
.dateitem {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  .key {
    color: rgb(156, 159, 173);
    margin-right: 20px;
    white-space: nowrap;
  }
  .value {
    display: inline-block;
    width: 80%;
  }
}
.ljzf {
  height: 1.5rem;
  background: #fff;
  display: flex;
  align-items: center;
  bottom: 0;
  text-align: center;
  position: fixed;
  width: 100%;
  overflow: hidden;
  justify-content: flex-end;


  .zfbutton {
     margin-right: 10px;
    // position: absolute;

  }
}
.van-count-down {
  line-height: normal;
}
</style>