<template>
  <div>
    <div class="doctorinfo">
      <div class="topinfo">
        <van-image
          round
          width="1.5rem"
          height="1.5rem"
          src="https://img01.yzcdn.cn/vant/cat.jpeg"
        />
        <div style="display: flex; align-items: center; padding-left: 10px">
          <div style="font-size: 20px; color: #fff">{{ ghinfo.name }}</div>
          <div style="font-size: 16px; color: #fff; margin-left: 10px">
            {{ ghinfo.ks_name }}
          </div>
        </div>
      </div>
      <div class="a" style="margin-top: 20px">
        <span style="margin-left: 10px; font-size: 16px; color: #fff"
          >简介</span
        >
      </div>
      <div style="font-size: 16px; color: #fff; margin-top: 20px">
        擅长中西医结合治疗痤疮、黄褐斑、面部皮炎、等损容性疾病、湿疹、荨麻疹、神经性皮炎
      </div>
    </div>
    <div class="dateinfo">
      <div style="padding: 10px">{{ ghinfo.ks_name }}</div>
      <div
        style="width: 100%; height: 1px; background-color: rgb(128, 128, 128)"
      ></div>
      <div
        style="
          padding: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div>
          <div style="display: flex; flex-wrap: wrap">
            <div style="width: 40px">{{ month }}-{{ day }}</div>
            <div style="width: 50px">{{ week }}</div>
            <div style="width: 40px">{{ ghinfo.apm }}</div>
            <div style="width: 40px">当天</div>
          </div>
          <div>
            <span>{{ ghinfo.reg_category_name }}</span>
            <span style="margin-left: 0.2rem">{{ ghinfo.reg_je }}</span>
          </div>
        </div>
        <van-button round type="info" @click="yh">余号:{{ number }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Drghdoctorinfo",
  data() {
    return {
      ghinfo: {},
      number: JSON.parse(this.$route.query.info).number,
      month: "",
      day: "",
      week: "",
    };
  },
  methods: {
    yh() {
      this.$store
        .dispatch("login/wxsyjzr", { openid:localStorage.getItem('openid') })
        .then(() => {
          const jzrinfo = this.$store.state["login"]["mrjzr"];
          if (JSON.stringify(jzrinfo) === "{}") {
            this.$dialog
              .confirm({
                message: "未添加就诊人，请前往添加，确认添加点击确定",
              })
              .then(() => {
                this.$router.push("addjzr");
              })
              .catch(() => {});
          } else {
            this.ghinfo.patient_name = jzrinfo.name
            this.ghinfo.sex = jzrinfo.sex_name
            this.ghinfo.birthday = jzrinfo.birthday
            this.ghinfo.mz = '汉'
            this.ghinfo.phone = jzrinfo.phone
            this.ghinfo.jt_address = jzrinfo.address
            this.ghinfo.is_mf = 0
            this.ghinfo.idcard = jzrinfo.idcard
            this.ghinfo.reg_nature = 2;
            this.ghinfo.openid = localStorage.getItem('openid');          
              this.$store.dispatch("drgh/clickgh", this.ghinfo)
              .then(() => {
                const tjinfo = this.$store.state["drgh"]["showghinfo"];
                localStorage.setItem("tjinfo", JSON.stringify(tjinfo));
                this.$router.push("ghtj");
              })
              .catch(() => {
                console.log("挂号失败");
              });
          }
        })
        .catch(() => {
          console.log("请求失败");
        });
    },
    getghinfo(){
      const ksindex = this.$route.query.ksindex;
      const weekindex = this.$route.query.weekindex;
      this.$store
        .dispatch("drgh/registenow")
        .then(() => {
          const ksdata = this.$store.state["drgh"]["ks_list"];
          this.number = ksdata[ksindex].doctor[weekindex].number;
        })
        .catch(() => {
          console.log("获取失败");
        });
      const info = JSON.parse(this.$route.query.info);
      const showdowninfo = {
        user_id: info.user_id,
        number: this.number,
      };
      this.$store
        .dispatch("drgh/showdn", showdowninfo)
        .then(() => {
          this.ghinfo = this.$store.state["drgh"]["ghinfo"];
        })
        .catch(() => {
          console.log("获取失败");
        });
    },
    datetime() {
      const date = new Date();
      this.month = date.getMonth() + 1;
      this.day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const week = date.getDay();
      const weeks = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      this.week = weeks[week];
    },
    // 重新获取数据
  },
  mounted() {
    this.getghinfo();
    this.datetime();
  },
};
</script>

<style lang="less" scoped>
.doctorinfo {
  height: 4rem;
  background: rgb(89, 181, 253);
  padding: 20px;
  .topinfo {
    display: flex;
    align-items: center;
  }
  .a::before {
    content: "|";
    color: #fff;
  }
}
.dateinfo {
  width: 90%;
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
}
</style>