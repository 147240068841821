<template>
  <div>
    <div class="search">
      <van-search
        v-model="value"
        placeholder="请输入搜索关键词"
        show-action
        action-text="我的预约"
      />
    </div>
    <div class="header">
      <div class="img">
        <img src="../../assets/2.jpg" alt="" />
      </div>
      <div class="content">
        <div class="title">城外城中医馆</div>
        <div class="addres">河南省郑州市中原区城外城博物馆1号楼201室</div>
      </div>
    </div>
    <div class="ks" style="display: flex;">
      <div
        class="title"
        style="
          height: 40px;
          width: 20%;
          background: #fff;
          margin-top: 10px;
          line-height: 40px;
        "
      >
        科室
      </div>
      <div
        style="
          width: 80%;
          height: 400px;
          margin-top: 10px;
          /* position: absolute;
          top: 0; */
          /* left: 20%; */
        "
      >
        <van-list
        :finished="finished"
        style="overflow-y: auto;width: 100%;height: 100%;"
        >
          <van-cell v-for="(item,index) in kslist" :key="index" :title="item.name" @click="doctorlist(index)"/>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Drgh",
  data() {
    return {
      value: "",
      finished:true,
      kslist:[]
    };
  
  },
  methods:{
    doctorlist(index){
     this.$router.push({path:'/Yyghdoctor',query:{ksindex:index}})
    }
  },
  mounted(){
    this.$store.dispatch('yygh/registeyy').then(()=>{
      this.kslist = this.$store.state['yygh']['ks']
      console.log(this.kslist)
    }).catch(()=>{
      console.log('获取科室失败')
    })
  }
};
</script>

<style lang="less" scoped>
.search {
 
  height: 1rem;
  background: #fff;
}
.header {
 
  height: 2rem;
  padding-left: 10px;
  background: #fff;
  margin-top: 20px;
  display: flex;
  align-items: center;
  .img {
    height: 1.5rem;
    width: 2rem;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    height: 1.5rem;
    width: 100%;

    position: relative;
    .title {
      position: absolute;
      margin-left: 10px;
    }
    .addres {
      position: absolute;
      bottom: 0;
      font-size: 12px;
      color: rgb(113, 115, 129);
      margin-left: 10px;
    }
  }
}
</style>