<template>
  <div>
    <div style="width: 100%;padding-bottom: 1.2rem;">
      <div class="peoplelist" v-for="(i, index) in peoplelist" :key="index" @click="getjzrinfo(i.id)">
        <div class="points">
          <div class="pont" v-for="(j, index) in 24" :key="index"></div>
        </div>
        <div style="display: flex">
          <div style="height: 0.8rem; width: 0.8rem; border-radius: 50%">
            <img
              src="../../assets/man.png"
              alt=""
              style="height: 0.8rem; width: 0.8rem; border-radius: 50%"
              v-show="i.sex==='男'"
            />
            <img
              src="../../assets/women.png"
              alt=""
              style="height: 0.8rem; width: 0.8rem; border-radius: 50%"
              v-show="i.sex==='女'"
            />
          </div>
          <div style="margin-left: 0.1rem">
            <div style="font-size: 20px; font-weight: 600">{{ i.name }}<span v-show="parseInt(i.age.split('岁')[0])<=16" style="font-size: 14px;color: rgb(235,239,246);">(低于16岁) </span></div>
            <div v-show="i.type" style="border: 1px solid rgb(91,153,236);text-align: center;border-radius: 7px;color: rgb(91,153,236);">{{ i.type }}</div>
            <div style="margin: 0.2rem 0 0.2rem 0">
              <span
                style="
                  background-color: rgb(240, 246, 253);
                  padding: 0.1rem;
                  color: rgb(91, 153, 236);
                "
                >{{ i.sex }}</span
              ><span
                style="
                  margin-left: 0.15rem;
                  background-color: rgb(240, 246, 253);
                  padding: 0.1rem;
                  color: rgb(91, 153, 236);
                "
                >{{ i.age }}</span
              >
            </div>
          </div>
        </div>
        <div
          style="height: 1px; width: 100%; background: rgb(235, 239, 246)"
        ></div>
        <div style="display: flex; justify-content: flex-end">
          <!-- <div style="margin: 0.2rem;border: 1px solid rgb(180,208,246);padding: 0.1rem;color: rgb(180,208,246);">就诊卡号</div> -->
          <div
            style="
              margin: 0.2rem 0 0.2rem 0.2rem;
              border: 1px solid rgb(180, 208, 246);
              padding: 0.1rem;
              color: rgb(180, 208, 246);
            "
          >
            详情
          </div>
        </div>
      </div>
    </div>
    <div class="addpeople" @click="addjzr">+添加就诊人</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      peoplelist: [
      ],
    };
  },
  methods: {
    addjzr() {
      this.$router.push("/addjzr");
    },
    getjzrlist() {
      this.$store
        .dispatch("jzrgl/wxjzrlist", { openid: localStorage.getItem('openid') })
        .then(() => {
          this.peoplelist = this.$store.state["jzrgl"]["jzrlist"];
        })
        .catch(() => {
          console.log("222");
        });
    },
    getjzrinfo(id){
      this.$router.push({path:'/addjzr',query:{edit:true,id:id}})
    }
  },
  mounted() {
    this.getjzrlist();
  },
};
</script>

<style scoped>
.peoplelist {
  /* height: 2rem; */
  width: 90%;
  background: #fff;
  margin: 0.2rem auto;
  padding: 0.2rem ;

}
.addpeople {
  width: 100%;
  height: 1rem;
  background: rgb(64, 158, 255);
  position: fixed;
  bottom: 0px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  line-height: 1rem;
}
.points {
  width: 1.8rem;
  height: 0.8rem;
  display: flex;
  position: absolute;
  right: 15px;
  flex-wrap: wrap;
}
.pont {
  height: 0.1rem;
  width: 0.1rem;
  background: rgb(241, 246, 253);
  border-radius: 50%;
  margin-left: 0.2rem;
}
</style>