import axios from 'axios'
import { MessageBox, Message } from "element-ui";
const service = axios.create({
    baseURL: "",
    timeout: 5000,
  });
  
  // request interceptor
  service.interceptors.request.use(
    // config：配置对象
   (config) => {
      // do something before request is sent
  
    //   if (store.getters.token) {
        // let each request carry token
        // ['X-Token'] is a custom headers key
        // please modify it according to the actual situation
        config.headers["Authorization"] = localStorage.getItem('token');
        // config.headers['Content-Type'] = 'application/json';
    //   }
      return config;
    },
    (error) => {
      // do something with request error
      console.log(error); // for debug
      return Promise.reject(error);
    }
  );
  
  // response interceptor
  service.interceptors.response.use(
    (response) => {
      const res = response.data;
      if(res.code ===undefined){
        return res
      }
      else{
        if (res.code !== 200) {
          // Message({
          //   message: res.msg || "Error",
          //   type: "error",
          //   duration: 5 * 1000,
          // });
       
          // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
          if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
            // to re-login
            MessageBox.confirm(
              "You have been logged out, you can cancel to stay on this page, or log in again",
              "Confirm logout",
              {
                confirmButtonText: "Re-Login",
                cancelButtonText: "Cancel",
                type: "warning",
              }
            ).then(() => {
            //   store.dispatch("user/resetToken").then(() => {
            //     location.reload();
            //   });
            });
          }
          return Promise.reject(new Error(res.message || "Error"));
        } else {
          return res;
        }
      }
      
    },
    (error) => {
      console.log("err" + error); // for debug
      // Message({
      //   message: error.message,
      //   type: "error",
      //   duration: 5 * 1000,
      // });
    

      return Promise.reject(error);
    }
  );
  
  export default service;
