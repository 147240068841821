import request from '@/utils/request'

export function wxjzrsave(data){
    return request({
      method:'post',
      url:'/api/wxjzrsave',
      data
    })
}

export function wxjzrlist(data){
    return request({
        method:'post',
        url:'api/wxjzrlist',
        data
    })
}

export function wxjzrinfo(data){
    return request({
        url:"api/wxjzrinfo",
        method:'post',
        data
    })

}

export function wxjzrdel(data){
    return request({
        url:'api/wxjzrdel',
        method:"post",
        data
    })
}

export function wxjzrqh(data){
    return request({
        url:'api/wxjzrqh',
        method:'post',
        data
    })
}

export function wxjzrdefault(data){
    return request({
        url:'api/wxjzrdefault',
        method:"post",
        data
    })
}
export function myy(data){
    return request({
        url:'api/myy',
        method:'post',
        data
    })
}
export function myy_detail(data){
    return  request({
        url:'api/myy_detail',
        method:"post",
        data
    })
}