import Vue from "vue";
import VueRouter from "vue-router";
import home from "@/views/Home/index.vue";
import Layout from "@/views/Layout/index.vue";
import Message from "@/views/Message/index";
import Zixun from "@/views/Zixun/index";
import My from "@/views/My/index";
import Reservation from "@/views/Reservation/index";
import Doctor from "@/views/Doctor/index";
import Drgh from "@/views/Drgh/index";
import Drghdoctor from "@/views/Drghdoctor/index";
import Drghdoctorinfo from "@/views/Drghdoctorinfo/index";
import Ghtj from "@/views/Ghtj/index";
import Drghzf from "@/views/Drghzf/index"
import Yyghdoctor from '@/views/Yyghdoctor/index'
import Yyghdoctorinfo from '@/views/Yyghdoctorinfo/index'
import Jzrgl from '@/views/Jzrgl'
import Addjzr from '@/views/Addjzr'
import Zhsz from '@/views/Zhsz'
import Editusername from '@/views/Editusername'
import Wdyy from "@/views/Wdyy"
import Qhjzr from '@/views/Qhjzr'
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    redirect:'home',
    children: [
      {
        path: "home",
        name: "home",
        component: home,
      },
      {
        path: "message",
        name: "Message",
        component: Message,
      },
      {
        path: "zixun",
        name: "Zixun",
        component: Zixun,
      },
      {
        path: "my",
        name: "My",
        component: My,
      },
    ],
  },
  {
    path: "/reservation",
    name: "Reservation",
    component: Reservation,
  },
  {
    path: "/doctor",
    name: "Doctor",
    component: Doctor,
  },
  {
    path: "/drgh",
    name: "Drgh",
    component: Drgh,
  },
  {
    path: "/drghdoctor",
    name: "Drgdoctor",
    component: Drghdoctor,
  },
  {
    path: "/drghdoctorinfo",
    name: "Drghdoctorinfo",
    component: Drghdoctorinfo,
  },
  {
    path: "/ghtj",
    name: "Ghtj",
    component: Ghtj,
  },
  {
    path:'/drghzf',
    name:'Drghzf',
    component:Drghzf
  },
  {
    path:'/yyghdoctor',
    name:'Yyghdoctor',
    component:Yyghdoctor
  },
  {
    path:'/yyghdoctorinfo',
    name:'Yyghdoctorinfo',
    component:Yyghdoctorinfo

  },
  {
    path:'/jzrgl',
    name:'Jzrgl',
    component:Jzrgl
  },
  {
    path:'/addjzr',
    name:'addjzr',
    component:Addjzr
  },
  {
    path:"/zhsz",
    name:'zhsz',
    component:Zhsz

  },
  {
    path:'/editusername',
    name:'Editusername',
    component:Editusername
  },
  {
    path:'/wdyy',
    name:'Wdyy',
    component:Wdyy
  },
  {
    path:'/qhjzr',
    name:'Qhjzr',
    component:Qhjzr
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
