<template>
  <div>
    <div class="header">
      <div>当天挂号时间为07:30-11:45,14:00-17:30</div>
      <div>在线预约时间为07:00-23:00</div>
    </div>
    <div class="weeklist">
      <div style="display: flex; font-size: 14px">
        <div
          v-for="(item, index) in weeks"
          :key="index"
          class="default"
          @click="getdoctor(index)"
          :class="{ active: active === index }"
        >
          {{ item.week }}
          <div style="text-align: center">{{ item.date }}</div>
        </div>
      </div>
    </div>
    <div class="doctorlist">
      <div class="doctoritem" v-for="(item, index) in doctorlist" :key="index">
        <div class="info">
          <div class="img">
            <van-image
              round
              width="1.5rem"
              height="1.5rem"
              src="https://img01.yzcdn.cn/vant/cat.jpeg"
            />
          </div>
          <div>
            <span style="margin-left: 10px; font-size: 20px">{{
              item.name
            }}</span>
            <span style="margin-left: 10px">主任中医师</span>
          </div>
          <van-button
            round
            type="info"
            style="margin-left: auto"
            @click="yygh(item)"
            v-show="item.number > 0"
          >
            <div>预约挂号</div>
            <div>剩余:{{ item.number }}</div>
          </van-button>
          <van-button
            round
            type="info"
            style="margin-left: auto"
            v-show="item.number === 0"
            disabled
            ><div>预约挂号</div>
            <div>剩余:{{ item.number }}</div></van-button
          >
        </div>
        <div style="margin-left: 1.7rem">
          擅长技术专长:以仲景理论及东恒学说为指归，立足脾胃，长于治疗热病、咳嗽等常见病，及积损劳伤、气血失调所致的各种疑难病。
        </div>
      </div>
    </div>
    <div>
      <van-popup v-model="show" position="bottom" closeable>
        <div class="xh">
          <div class="xh-header">{{ xhdata.date }}</div>
          <div v-for="(item, index) in xhlist" :key="index">
            <div style="font-size: 16px; margin-left: 0.2rem">
              {{ item.apm }}
            </div>
            <div class="hmlist">
              <div
                class="hmitem"
                v-for="(j, index) in item.datelist"
                :key="index"
                @click="clickh(j, item.apm)"
              >
                <div>{{ j.date }}</div>
                <div>{{ j.number }}号</div>
              </div>
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "Drghdoctor",
  data() {
    return {
      show: false,
      weeks: [],
      doctorlist: [],
      clickhinfo: {
        user_id: "",
        h: "",
        date: "",
        time: "",
        apm: "",
      },
      ksindex: 0,
      active: 0,
      date: "",
      xhdata: {},
      xhlist: [],
      ghinfo: {},
      getghlistinfo: {
        user_id: "",
        number: "",
        date: "",
      },
    };
  },
  methods: {
    yygh(info) {
      this.getghlistinfo.user_id = info.user_id;
      this.clickhinfo.user_id = info.user_id;
      this.getghlistinfo.number = info.number.toString();
      this.getghlistinfo.date = this.date;
      this.$store
        .dispatch("yygh/showdnyy", this.getghlistinfo)
        .then(() => {
          this.xhdata = this.$store.state["yygh"]["xhlist"];
          this.xhlist = this.xhdata.data;
          console.log(this.xhlist);
          this.show = true;
        })
        .catch(() => {
          console.log("222");
        });
    },
    getweeks() {
      this.ksindex = this.$route.query.ksindex;
      this.$store
        .dispatch("yygh/registeyy")
        .then(() => {
          const kslist = this.$store.state["yygh"]["ks"];
          this.weeks = kslist[this.ksindex].weeks;
          this.date = this.weeks[0].full_date;
          this.clickhinfo.date = this.date;
          this.doctorlist = this.weeks[0].doctor;
        })
        .catch(() => {
          console.log("获取失败");
        });
    },
    getdoctor(index) {
      this.active = index;
      console.log(this.active, index);
      this.ksindex = this.$route.query.ksindex;
      this.$store
        .dispatch("yygh/registeyy")
        .then(() => {
          const kslist = this.$store.state["yygh"]["ks"];
          this.date = kslist[this.ksindex].weeks[index].full_date;
          this.clickhinfo.date = this.date;
          this.doctorlist = kslist[this.ksindex].weeks[index].doctor;
        })
        .catch(() => {
          console.log("获取失败");
        });
    },
    clickh(item, apm) {
      this.$store
        .dispatch("login/wxsyjzr", { openid:localStorage.getItem('openid') })
        .then(() => {
          const jzrinfo = this.$store.state["login"]["mrjzr"];
          if (JSON.stringify(jzrinfo) === "{}") {
            this.$dialog
              .confirm({
                message: "未添加就诊人，请前往添加，确认添加点击确定",
              })
              .then(() => {
                this.$router.push("addjzr");
              })
              .catch(() => {});
          } else {
            this.clickhinfo.h = item.number;
            this.clickhinfo.time = item.date;
            this.clickhinfo.apm = apm;
            this.$store
              .dispatch("yygh/clickh", this.clickhinfo)
              .then(() => {
                this.ghinfo = this.$store.state["yygh"]["clickh"];
                this.ghinfo.patient_name = jzrinfo.name;
                this.ghinfo.sex = jzrinfo.sex_name;
                this.ghinfo.birthday = jzrinfo.birthday;
                this.ghinfo.mz = "汉";
                this.ghinfo.phone = jzrinfo.phone;
                this.ghinfo.jt_address = jzrinfo.address;
                this.ghinfo.is_mf = 0;
                this.ghinfo.idcard = jzrinfo.idcard;
                this.ghinfo.reg_nature = 2;
                this.ghinfo.openid =localStorage.getItem('openid');
                if (this.ghinfo.apm === 0) {
                  this.ghinfo.apm = "上午";
                } else {
                  this.ghinfo.apm = "下午";
                }
                this.clickgh(this.ghinfo);
              })
              .catch(() => {
                console.log("22");
              });
          }
        });
    },
    clickgh(ghinfo) {
      this.$store
        .dispatch("yygh/clickghyy", ghinfo)
        .then(() => {
          this.$router.push({ path: "ghtj", query: { ghway: "预约挂号" } });
        })
        .catch(() => {
          console.log("err");
        });
    },
  },
  mounted() {
    this.getweeks();
  },
};
</script>
  
  <style lang="less" scoped>
.header {
  width: 100%;
  height: 2rem;
  background-color: rgb(255, 251, 232);
  color: rgb(238, 122, 69);
  font-size: 20px;
}
.weeklist {
  font-size: 20px;
  font-weight: 400;
  align-items: center;
  height: 1rem;
  display: flex;

  background: #fff;
}
.default {
  border-radius: 3px;
  margin-left: 0.1rem;
  border: 1px solid rgb(64, 194, 248);
  background-color: rgb(64, 194, 248);
  color: #fff;
}
.active {
  background: rgb(8, 129, 217);
}
.doctorlist {
  .doctoritem {
    padding: 10px;
    background: rgb(255, 255, 255);
    margin-top: 10px;

    .info {
      display: flex;
      align-items: center;
      .img {
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
      }
    }
  }
}
.xh {
  .xh-header {
    width: 100%;
    background: rgb(248, 248, 248);
    text-align: center;
    padding: 0.4rem 0 0.4rem 0;
    margin-bottom: 0.2rem;
    font-size: 16px;
  }
  .hmlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0.2rem;
    .hmitem {
      width: 1rem;
      height: 0.8rem;
      margin-top: 0.1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      background: rgb(238, 245, 255);
    }
  }
}
</style>