<template>
  <div class="body">
    <div class="header">
      <img src="../../assets/2.jpg" alt="" />
    </div>
    <div class="content">
      <div v-if="defaultjzr" class="list">
        <div style="padding-left: 10px">
          <div style="display: flex; align-items: center">
            <div style="font-size: 20px">{{ mrjzrinfo.name }}</div>
            <div
              @click="qh"
              style="
                border: 1px solid rgb(91, 153, 236);
                border-radius: 5px;
                padding: 4px 10px;
                color: rgb(91, 153, 236);
              "
            >
              切换
            </div>
          </div>
          <div style="display: flex">
            <div>{{ mrjzrinfo.sex_name }}</div>
            <div style="margin-left: 5px">{{ mrjzrinfo.age }}</div>
          </div>
        </div>
      </div>
      <div
        v-if="defaultjzr === false"
        class="list"
        style="justify-content: center"
        @click="addjzr"
      >
        <div
          style="
            border: 1px dotted;
            font-size: 0.5rem;
            padding: 0.3rem 1rem;
            border-radius: 0.8rem;
          "
        >
          添加就诊人
        </div>
      </div>
      <div class="list">
        <div class="item-list" @click="yy">
          <div class="icon">
            <van-icon name="todo-list" size="0.4rem" color="#fff" />
          </div>
          <div class="title">预约挂号</div>
        </div>
        <div class="item-list">
          <div class="icon" style="background: rgb(70, 214, 203)">
            <van-icon name="browsing-history" size="0.4rem" color="#fff" />
          </div>
          <div class="title">报告查询</div>
        </div>
        <div class="item-list">
          <div class="icon" style="background: rgb(255, 219, 52)">
            <van-icon name="balance-list" size="0.4rem" color="#fff" />
          </div>
          <div class="title">门诊缴费</div>
        </div>
      </div>
      <div class="list">
        <div class="item" @click="drgh">
          <van-icon name="notes-o" size="0.4rem" color="blue" />
          <div>当日挂号</div>
        </div>
        <div class="item">
          <van-icon name="notes-o" size="0.4rem" color="blue" />
          <div>我的预约</div>
        </div>
        <div class="item">
          <van-icon name="notes-o" size="0.4rem" color="blue" />
          <div>就诊人管理</div>
        </div>
        <div class="item">
          <van-icon name="notes-o" size="0.4rem" color="blue" />
          <div>智能自诊</div>
        </div>
      </div>
      <div class="list">
        <div class="item">
          <van-icon name="notes-o" size="0.4rem" color="blue" />
          <div class="title">住院预交</div>
        </div>
        <div class="item">
          <van-icon name="notes-o" size="0.4rem" color="blue" />
          <div class="title">住院查询</div>
        </div>
        <div class="item">
          <van-icon name="notes-o" size="0.4rem" color="blue" />
          <div class="title">订阅号</div>
        </div>
      </div>
      <div class="list">
        <div class="item">
          <van-icon name="notes-o" size="0.4rem" color="blue" />
          <div class="title">来院导航</div>
        </div>
        <div class="item">
          <van-icon name="notes-o" size="0.4rem" color="blue" />
          <div class="title">排队叫号</div>
        </div>
        <div class="item">
          <van-icon name="notes-o" size="0.4rem" color="blue" />
          <div class="title">门诊患者就诊流程</div>
        </div>
        <div class="item">
          <van-icon name="notes-o" size="0.4rem" color="blue" />
          <div class="title">防疫组合方剂</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { test } from "../../api/weixin.js";
export default {
  name: "Home",
  data() {
    return {
      name: "",
      openid: "",
      islogin: "",
      defaultjzr: "",
      mrjzrinfo: {
        age: "",
        name: "",
        sex: "",
      },
    };
  },
  mounted() {
    // this.login();
    this.mrjzr();
     this.openid = localStorage.getItem("openid");
    if (this.openid === null || this.openid === "undefined") {
      const url = window.location.href;
      const code = this.geturlcode(url);
      if (code&&code !=localStorage.getItem('code')) {
        localStorage.setItem('code',code)
        test(code)
          .then((response) => {
            console.log(response.data.openid);
            localStorage.setItem("openid", response.data.openid);
            localStorage.setItem("token", response.token);
            this.mrjzr();
          })
          .catch(() => {
            console.log('没有获取登陆数据')
           
          });
      } else {
        const appid = "wxcd07cf8a7e2d6e7a";
        let local = "https://gzh.cwc999.com";
        const href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          appid +
          "&redirect_uri=" +
          encodeURIComponent(local) +
          "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
        window.location.href = href;
      }
    } else {
      // const url = window.location.href;
      // const code = this.geturlcode(url);
      // if (code) {
      //   test(code).then((response) => {
      //     console.log("自动授权登陆=", response.data.openid);
      //     localStorage.setItem("openid", response.data.openid);
      //     this.mrjzr()
      //   });
      // } else {
      //   const appid = "wxcd07cf8a7e2d6e7a";
      //   let local = "https://gzh.cwc999.com/home";
      //   const href =
      //     "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
      //     appid +
      //     "&redirect_uri=" +
      //     encodeURIComponent(local) +
      //     "&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect";
      //   window.location.href = href;
      // }
    }
  },

  methods: {
    // 获取默认
    mrjzr() {
      this.$store
        .dispatch("login/wxsyjzr", { openid: localStorage.getItem("openid") })
        .then(() => {
          this.mrjzrinfo = this.$store.state["login"]["mrjzr"];
          if (this.mrjzrinfo.name) {
            this.defaultjzr = true;
          } else {
            this.defaultjzr = false;
          }
          console.log(this.defaultjzr);
        })
        .catch(() => {
          console.log("22");
        });
    },
    addjzr() {
      this.$router.push("addjzr");
    },
    qh() {
      this.$router.push("qhjzr");
    },
    yy() {
      this.$router.push("reservation");
    },
    drgh() {
      this.$router.push("drgh");
    },
    geturlcode(url) {
      console.log("111");
      let regstr = "/(\\?|\\&)" + "code" + "=([^\\&]+)/";
      let reg = eval(regstr);
      let result = url.match(reg);
      console.log(result);
      if (result && result[2]) {
        return result[2];
      }
    },
    // login() {
    //   if (!localStorage.getItem("token")) {
    //     console.log(localStorage.getItem("token"));
    //     this.$store
    //       .dispatch("login/login", { user_name: "dy", password: "123456" })
    //       .then(() => {
    //         console.log("登陆成功");
    //         localStorage.setItem('openid','onmGM6HAGJMhp3qsd22sF0m5ev2Q')
    //         this.mrjzr();
    //       })
    //       .catch(() => {
    //         console.log("登陆失败");
    //       });
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  img {
    width: 100%;
    height: 100px;
  }
}
.content {
  width: 100%;
  overflow-y: scroll;
  .list {
    display: flex;
    height: 2rem;
    border-radius: 10px;
    width: 95%;
    margin: 10px auto;
    justify-content: space-between;
    align-items: center;
    background: rgb(255, 255, 255);
    .item-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 20px;
      .icon {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background: rgb(70, 213, 139);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .list .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 2%;
  }
}
</style>