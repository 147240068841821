import request from '@/utils/request'

export function registeyy(){
    return request({
        url:'api/reg/registeyy',
        method:'post',
    })

}

export function showdnyy(data){
    return request({
        url:'api/reg/showdnyy',
        method:'post',
        data
    })
}

export function clickh(data){
    return request({
        url:'api/reg/clickh',
        method:'post',
        data

    })

}

export function clickghyy(data){
    return request({
        url:'api/reg/clickghyy',
        method:'post',
        data
    })
}

export function clicktjyy(data){
    return request({
        url:"api/reg/clicktjyy",
        method:'post',
        data
    })
}

