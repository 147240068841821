<template>
  <div class="content">
    <div class="jzrinfo" v-for="(i, index) in jzrlist" :key="index">
      <div>
        <div>
          <span style="font-size: 16px;">{{ i.name }}</span>
          <span style="margin-left: 10px;border: 1px solid rgb(91,153,236);padding: 5px;border-radius: 5px;" v-show="i.type">{{ i.type }}</span>
        </div>
        <div style="margin-top: 10px;">{{ i.idcard }}</div>
      </div>
      <div @click="qh(i)"><van-icon name="circle" v-show="!i.type" size="20px"/></div>
      <van-icon name="checked" color="rgb(91,153,236)" v-show="i.type" size="20px"/>
    </div>
  </div>
</template>
<script>
export default {
  name: "Qhjzr",
  data() {
    return {
      jzrlist: [],
    };
  },
  methods: {
    getjzrlist() {
      this.$store
        .dispatch("jzrgl/wxjzrqh", { openid: localStorage.getItem('openid') })
        .then(() => {
          const reg = /^(.{1})(?:\d+)(.{4}$)/
          this.jzrlist = this.$store.state["jzrgl"]["qhjzrlist"];
          for(var i =0;i<this.jzrlist.length;i++){
            this.jzrlist[i].idcard = this.jzrlist[i].idcard.replace(reg,'\$1*************\$2')
          }
        })
        .catch(() => {
          console.log("22");
        });
    },
    qh(item){
        this.$store.dispatch('jzrgl/wxjzrdefault',{openid:localStorage.getItem('openid'),id:item.id}).then(()=>{
            this.getjzrlist()
        }).catch(()=>{
            console.log('222')
        })

    }
  },
  mounted() {
    this.getjzrlist();
  },
};
</script>

<style scoped lang="less">
.content {
  .jzrinfo {
    width: 100%;
    background: #fff;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px;
    align-items: center;
  }
}
.circle{
    color:  rgb(91,153,236);
}
</style>