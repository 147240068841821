<template>
  <div>
    <div class="header">
      <div>当天挂号时间为07:30-11:45,14:00-17:30</div>
      <div>在线预约时间为07:00-23:00</div>
    </div>
    <div class="week">
      <div>全部</div>
      <div>
        <van-tabs v-model="active">
          <van-tab title="标签 1" name="a">内容 1</van-tab>
          <van-tab title="标签 2" name="b">内容 2</van-tab>
          <van-tab title="标签 3" name="c">内容 3</van-tab>
          <van-tab title="标签 4" name="d">内容 4</van-tab>
          <van-tab title="标签 3" name="c">内容 3</van-tab>
          <van-tab title="标签 4" name="d">内容 4</van-tab>
          <van-tab title="标签 3" name="c">内容 3</van-tab>
          <van-tab title="标签 4" name="d">内容 4</van-tab>
          <van-tab title="标签 3" name="c">内容 3</van-tab>
          <van-tab title="标签 4" name="d">内容 4</van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Doctor",
  data() {
    return {
      active: "a",
    };
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 2rem;
  background-color: rgb(255, 251, 232);
  color: rgb(238, 122, 69);
  font-size: 20px;
}
.week{
    display: flex;
}
</style>