<template>
  <div>
    <van-cell-group style="margin-top: 0.2rem;">
      <van-field v-model="username" maxlength="25"/>
    </van-cell-group>
    <div style="padding: 0.3rem;font-size: 12px;color: rgb(144,129,129);">支持输入25个字以内的中文、英文大小写和数字组合</div>
    <div style="padding: 0.3rem;">
    <div style="width: 100%;height: 0.8rem;background: rgb(91,153,236);text-align: center;margin: 0 auto;line-height: 0.8rem;border-radius: 5px;font-size: 16px;color: #fff;">保存</div>
</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "范万里",
    };
  },
};
</script>

<style scoped lang="less">
</style>