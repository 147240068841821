import {login,wxsyjzr} from '../../api/weixin.js'
const getDefaultState = ()=>{
    return {
        token:localStorage.getItem('token')||'',
        mrjzr:{},
    }
}

const state = getDefaultState()

const mutations ={
    SET_TOKEN:(state,token)=>{
        state.token = token

    },
    SET_MRJZR:(state,mrjzr)=>{
        state.mrjzr = mrjzr
    }
    

}

const actions = {
    login({commit},data){
        return new Promise((resolve,reject)=>{
            login(data).then((response)=>{
                console.log(response)
                commit('SET_TOKEN',response.token)
                localStorage.setItem('token',response.token) 
                resolve()
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    wxsyjzr({commit},data){
        return new Promise((resolve,reject)=>{
            wxsyjzr(data).then((response)=>{
                console.log(response.data)
                commit('SET_MRJZR',response.data)
                resolve()
            }).catch(()=>{
                reject()
            })

        })
    }

}


export default{
    namespaced:true,
    state,
    actions,
    mutations
}