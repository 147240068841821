import request from '../utils/request'

export function test(code){
    return request({
        url:'/api/wxlogin',
        params:{
            code:code
        },
        method:'get'
    })
}

export function login(data){
    return request({
        url:'/api/login',
        method:'post',
        data
    })

}

export function wxsyjzr(data){
    return request({
        url:'/api/wxsyjzr',
        method:'post',
        data
    })
}

export function wx_gzh_pay(data){
    return request({
        url:'wx_gzh_pay',
        method:'post',
        data
    })
}

