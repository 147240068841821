import request from '@/utils/request'

export function registenow(){
    return request({
        method:'post',
        url:"api/reg/registenow"
    })

}

export function showdn(data){
    return request({
        method:'post',
        url:'api/reg/showdn',
        data
    })
}

export function clickgh(data){
    return request({
        method:'post',
        url:'api/reg/clickgh',
        data
    })
}

export function clicktj(data){
    return request({
        method:'post',
        url:'api/reg/clicktj',
        data
    })
}

export function refushord(data){
    return request({
        method:"post",
        url:'api/reg/refushord',
        data
    })
}