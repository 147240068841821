<template>
  <div class="content">
    <div
      v-for="(item, index) in ghlist"
      :key="index"
      style="margin-top: 0.2rem;background:#fff;padding:0 0.2rem 0 0.2rem"
      @click="yyxq(item)"
    >
      <div class="item-doctor">
        <div style="display: flex; align-items: center">
          <div class="title" style="font-size: 16px">{{ item.doctor_name }}</div>
          <div style="margin-left: 0.1rem; font-size: 16px">{{ item.title }}</div>
          <div style="margin-left: auto;color: red;" >{{ item.status }}</div>
        </div>
        <div
          style="display: flex; align-items: center; color: rgb(157, 160, 174)"
        >
          <div class="title">{{ item.ks_name }}</div>
          <div style="margin-left: 0.1rem">{{ item.org_name }}</div>
        </div>
      </div>
      <div class="item-patient">
        <div style="display: flex; align-items: center">
          <div
            style="
              width: 80px;
              height: 40px;
              line-height: 40px;
              color: rgb(157, 160, 174);
            "
          >
            就诊人
          </div>
          <div style="margin-left: 0.2rem">{{ item.patient_name }}</div>
        </div>
        <div style="display: flex; align-items: center">
          <div
            style="
              width: 80px;
              height: 40px;
              line-height: 40px;
              color: rgb(157, 160, 174);
            "
          >
            候诊时间
          </div>
          <div style="margin-left: 0.2rem">{{ item.hz_time}}</div>
        </div>
        <div style="display: flex; align-items: center">
          <div
            style="
              width: 80px;
              height: 40px;
              line-height: 40px;
              color: rgb(157, 160, 174);
            "
          >
            门诊类型
          </div>
          <div style="margin-left: 0.2rem">{{ item.reg_category.split(' ')[0] }}<span style="margin-left: 0.1rem;color: red;">{{item.reg_category.split(' ')[1]  }}</span></div>
        </div>
        <div style="display: flex; align-items: center">
          <div
            style="
              width: 80px;
              height: 40px;
              line-height: 40px;
              color: rgb(157, 160, 174);
            "
          >
            提交时间
          </div>
          <div style="margin-left: 0.2rem">{{ item.created_time }}</div>
        </div>
        <!-- <div style="display: flex; align-items: center">
          <div
            style="
              width: 80px;
              height: 40px;
              line-height: 40px;
              color: rgb(157, 160, 174);
            "
          >
            失败原因
          </div>
          <div
            style="
              margin-left: 0.2rem;
              width: 70%;
              height: auto;
              word-wrap: break-word;
            "
          >
            sxxxxxxxxxx
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { stringify } from 'querystring';
export default {
  data() {
    return {
      ghlist: [],
      detail:[]
    };
  },
  methods: {
    getyylist() {
      this.$store
        .dispatch("jzrgl/myy",{openid:localStorage.getItem('openid')})
        .then(() =>{
          this.ghlist = this.$store.state["jzrgl"]["ghlist"];
        })
        .catch(() =>{
          console.log("2");
        });
    },
    yyxq(info){
        const xqobject = {}
        xqobject.openid = info.openid
        xqobject.order_id = info.order_id
        this.$store.dispatch('jzrgl/myy_detail',xqobject).then(()=>{
         this.detail = this.$store.state['jzrgl']['detail']
         console.log(this.detail)
         localStorage.setItem('zfinfo',JSON.stringify(this.detail))
         this.$router.push('/drghzf')
        }).catch(()=>{
            console.log('2')
        })
    }
  },
  mounted() {
    this.getyylist();
  },
};
</script>

<style lang="less" scoped>

.title {
  width: 80px;
  height: 40px;
  line-height: 40px;
}
</style>