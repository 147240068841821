<template>
  <div>
    <div class="header">
      <div class="avatar"><img src="../../assets/logo.png" alt="" /></div>
      <div style="margin-left: 0.4rem; font-size: 16px; color: #fff">
        
      </div>
      <div
        style="margin-left: auto; margin-right: 0.4rem; color: #fff"
        @click="zhsz"
      >
        账号设置<van-icon name="arrow" />
      </div>
    </div>
    <div class="contend">
      <div class="icon-item" @click="jzrgl">
        <div class="icon">
          <van-icon name="friends" color="#fff" size="0.4rem" />
        </div>
        <div style="margin-left: 0.2rem">就诊人管理</div>
      </div>
      <div>
        <div style="width: 100%; height: 1px; background-color: rgb(230, 230, 230);"></div>
      </div>
      <div class="icon-item" @click="wdyy">
        <div class="icon">
          <van-icon name="friends" color="#fff" size="0.4rem" />
        </div>
        <div style="margin-left: 0.2rem" >我的预约</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {
    jzrgl() {
      this.$router.push("/jzrgl");
    },
    zhsz() {
      this.$router.push("/zhsz");
    },
    wdyy() {
      this.$router.push("/wdyy");
    },
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.header {
  width: 100%;
  background-color: rgb(42, 138, 234);
  height: 1rem;
  display: flex;
  align-items: center;
}
.avatar {
  height: 0.7rem;
  width: 0.7rem;
  border-radius: 50%;
  margin-left: 0.2rem;
}
img {
  height: 0.7rem;
  width: 0.7rem;
  border-radius: 50%;
}
.contend {
  .icon-item {
    width: 100%;
    display: flex;
    background: #fff;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    .icon {
      background: rgb(96, 198, 247);
      padding: 0.15rem;
      border-radius: 0.3rem;
      // margin: 10px;
    }
  }
}
</style>