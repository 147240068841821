import { wxjzrsave,wxjzrlist,wxjzrinfo,wxjzrdel,wxjzrqh,wxjzrdefault,myy,myy_detail} from "@/api/jzrgl"
const getDefaultState = ()=>{
    return {
        jzrlist:[],
        jzrinfo:{},
        qhjzrlist:[],
        ghlist:[],
        detail:[],

    }
}
const state = getDefaultState()
const actions ={
    wxjzrsave({commit},data){
        return new Promise((resolve,reject)=>{
            wxjzrsave(data).then((response)=>{
                console.log(response)
                resolve()
            }).catch(()=>{
                reject()
            
            })
        })
    },
    wxjzrlist({commit},data){
        return new Promise((resolve,reject)=>{
            wxjzrlist(data).then((response)=>{
                commit('SET_JZRLIST',response.data)
                resolve()
            }).catch(()=>{
               
                reject()
            })
        })
    },
    wxjzrinfo({commit},data){
        return new Promise((resolve,reject)=>{
            wxjzrinfo(data).then((response)=>{
                commit('SET_JZRINFO',response.data)
                resolve()
            }).catch(()=>{
                reject()
            })
        })
    },
    wxjzrdel({commit},data){
        return new Promise((resolve,reject)=>{
            wxjzrdel(data).then((response)=>{
                resolve()
            }).catch(()=>{
                reject()
            })

        })
        
    },
    wxjzrqh({commit},data){
        return new Promise((resolve,reject)=>{
            wxjzrqh(data).then((response)=>{
                console.log(response.data)
                commit('SET_QHJZRLIST',response.data)
                resolve()
            }).catch(()=>{
                reject()
            })
        })
    },
    wxjzrdefault({commit},data){
        return new Promise((resolve,reject)=>{
            wxjzrdefault(data).then((resposne)=>{
                console.log(resposne)
                resolve()
            }).catch(()=>{
                reject()
            })
        })
    },
    myy({commit},data){
        return new Promise((resolve,reject)=>{
            myy(data).then((response)=>{
                console.log(response)
                commit('SET_GHLIST',response.data)
                resolve()
            }).catch(()=>{
                reject()
            })

        })
    },
    myy_detail({commit},data){
        return new Promise((resolve,reject)=>{
            myy_detail(data).then((response)=>{
                commit('SET_DETAIL',response.data)
                resolve()
            }).catch(()=>{
                reject(
                    
                )
            })
        })
    }

}
const mutations = {
    SET_JZRLIST:(state,jzrlist)=>{
        state.jzrlist = jzrlist

    },
    SET_JZRINFO:(state,jzrinfo)=>{
        state.jzrinfo = jzrinfo
    },
    SET_QHJZRLIST:(state,qhjzrlist)=>{
        state.qhjzrlist = qhjzrlist
    },
    SET_GHLIST:(state,ghlist)=>{
        state.ghlist = ghlist
    },
    SET_DETAIL:(state,detail)=>{
        state.detail = detail
    }
    

}

export default{
    namespaced:true,
    state,
    actions,
    mutations
}
