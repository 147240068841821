<template>
  <div>
    <div class="header">
      <div>当天挂号时间为07:30-11:45,14:00-17:30</div>
      <div>在线预约时间为07:00-23:00</div>
    </div>
    <div class="doctorlist">
      <div class="doctoritem" v-for="(item,index) in doctorlist " :key="index">
        <div class="info">
          <div class="img">
            <van-image
              round
              width="1.5rem"
              height="1.5rem"
              src="https://img01.yzcdn.cn/vant/cat.jpeg"
            />
          </div>
          <div>
            <span style="margin-left: 10px;font-size: 20px;">{{item.name}}</span>
            <span style="margin-left: 10px;">主任中医师</span>
          </div>
          <van-button round type="info" style="margin-left: auto;" @click="drgh(item,index)">当日挂号</van-button>          
        </div>
        <div style="margin-left: 1.7rem">
          擅长技术专长:以仲景理论及东恒学说为指归，立足脾胃，长于治疗热病、咳嗽等常见病，及积损劳伤、气血失调所致的各种疑难病。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Drghdoctor",
 data(){
    return{
      doctorlist:[],
      ksindex:0

    }
 },
 methods:{
    drgh(item,index){
        this.$router.push({path:'/drghdoctorinfo',query:{info:JSON.stringify(item),ksindex:this.ksindex,weekindex:index}})
    },
    getdoctor(){
      this.ksindex =parseInt(this.$route.query.ksindex)
      console.log(this.ksindex)
      this.$store.dispatch('drgh/registenow').then(()=>{
         const ksdata = this.$store.state['drgh']['ks_list']
         this.doctorlist = ksdata[this.ksindex].doctor
      }).catch(()=>{
        console.log('error')
      })
    }
 },
 mounted(){
  this.getdoctor()
 }
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 2rem;
  background-color: rgb(255, 251, 232);
  color: rgb(238, 122, 69);
  font-size: 20px;
}
.doctorlist {
  .doctoritem {
    padding: 10px;
    background: rgb(255,255,255);
    margin-top: 10px;

    .info {
      display: flex;
      align-items: center;
      .img {
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
      }
     
    }
  }
}
</style>