<template>
  <div class="content">
    <div class="warm">隐私保护,以下信息仅对接医生可见</div>
    <div class="type">
        <span>就诊人类型</span>
        <span class="a" :class="{c:defaultchange}" @click="people">成人</span>
        <span class="b" :class="{c:!defaultchange}" @click="children">儿童（小于16岁）</span>
    </div>
    <div class="info">
      <van-cell-group>
        <van-field required v-model="jzrinfo.name" label="就诊人姓名" placeholder="必填，请输入真是姓名" input-align="left"/>
        <van-field @input="getbirthday(jzrinfo.idcard)" :error-message="errmessage" required v-model="jzrinfo.idcard" label="身份证号" placeholder="必填，请输入证件号码" input-align="left" @blur="idchecked(jzrinfo.idcard)"/>
        <van-field v-show="defaultchange" input-align="" required v-model="jzrinfo.phone" label="手机号码" placeholder="必填，请输入手机号码"></van-field>
        <van-field label="性别" readonly >
            <template #button>
                <div style="display: flex;" class="sexlist">
                    <div slot="button" class="sex" @click="changesex('1')" :class="{defaultsex:sexshow}">男</div>
                    <div slot="button" class="sex" @click="changesex('0')" :class="{defaultsex:!sexshow}">女</div>
                </div>
            </template>
        </van-field>
        <van-field placeholder="请选择日期"  input-align="" label="出生日期" clickable readonly :value="jzrinfo.birthday" @click="showtime=true"></van-field>
        <van-field input-align="" readonly required clickable label="家庭住址" :value="jzrinfo.address" placeholder="请选择所在地区" @click="bindShow" />
        <van-field :value="jzrinfo.ybtype" clickable readonly input-align="" required label="医保类型" placeholder="请选择" @click="showtype=true"></van-field>
      </van-cell-group>
    </div>
    <div style="font-size: 16px;margin-top: 0.2rem;" v-show="!defaultchange">陪诊人信息</div>
    <div class="phrinfo" style="margin-top: 0.2rem;" v-show="!defaultchange">
        <van-cell-group>
            <van-field label="陪诊人姓名" v-model="jzrinfo.pzrname" placeholder="必填,请输入真是姓名"></van-field>
            <van-field v-model="jzrinfo.pzridcard" label="证件号码" placeholder="必填,请输入证件号码"></van-field>
            <van-field v-model="jzrinfo.phone" label="手机号码" placeholder="必填,请输入手机号号码"></van-field>
        </van-cell-group>
    </div>
    <!-- 选择时间 -->
    <van-popup v-model="showtime" position="bottom" >
        <van-datetime-picker v-model="currentDate" 
        type="date" 
        title="选择年月日" 
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="suretime"
        @cancel = 'canceltime'
        :formatter="formatter"
        />
    </van-popup>
    <!-- 地区选择框 -->
    <van-popup v-model="showArea" position="bottom">
        <van-area :area-list="areaList" @confirm="onAreaConfirm" @cancel="bindCancel"/>  
    </van-popup>
    <!-- 医保选择框 -->
    <van-popup  v-model="showtype" position="bottom" style="height: 40%;">
        <van-picker show-toolbar :columns="yblist" @confirm="sure">

        </van-picker>
    </van-popup>
    <div @click="save" style="width: 90%;background-color: rgb(92,162,255);margin: 0.5rem auto;text-align: center;padding: 0.2rem;border-radius: 4px;color: #fff;font-size: 16px;">保存</div>
    <div v-show="this.$route.query.edit" @click="deljzr" style="text-align: center;font: 16px;color: rgb(142,142,142);">删除就诊人</div>
  </div>
</template>

<script>
import AreaInfo from '../../area/area'
export default {
    data(){
        return{
            // 时间选择数据
            minDate:new Date(1900,0,1),
            maxDate:new Date(2023,11,31),
            currentDate:new Date(2021,0,17),
            // 
            defaultchange:true,
            showArea:false,
            showtype:false,
            showtime:false,
            sexshow:true,
            errmessage:'',
            jzrinfo:{
                name:'',
                idcard:'',
                address:'',
                phone:"",
                ybtype:'',
                sex:1,
                birthday:"",
                pzrname:'',
                pzridcard:'',
                type:0,
                id:0
            },
            yblist:['自费','医保'],
            areaList:AreaInfo.area,
        }
    },
    methods:{
    // 保存信息
    save(){
        const newjzr = JSON.parse(JSON.stringify(this.jzrinfo))
        if(newjzr.ybtype==='自费'){
            newjzr.ybtype=0
        }else{ 
            newjzr.ybtype=1
        }
        newjzr.openid = localStorage.getItem('openid')
        this.$store.dispatch('jzrgl/wxjzrsave',newjzr).then(()=>{
            // this.$router.push('/jzrgl')
            this.$router.back(1)
        }).catch(()=>{
            console.log('22')
        })
    },
    // 选择时间
    suretime(value){
        console.log(value)
        this.showtime = false
        const year = value.getFullYear()
        const month = (value.getMonth()+1).toString().padStart(2,'0')
        const day = (value.getDate()).toString().padStart(2,'0')
        this.jzrinfo.birthday = year+'-'+month+'-'+day

    },
    canceltime(){
        this.showtime = false
    },
    // 修改时间格式
    formatter(type,value){
        if(type==='year'){
            return `${value}年`
        }else if(type==='month'){
            return `${value}月`
            
        }else{
            return `${value}日`
        }
        return value

    },
    // 选择成人
    people(){
        this.defaultchange = true
        this.jzrinfo.type=0
        this.jzrinfo.pzridcard = ''
        this.jzrinfo.pzrname = ''
        this.jzrinfo.sex = 1
        this.jzrinfo.birthday = ''

    },
    children(){
        this.defaultchange=false
        this.jzrinfo.type=1
    },
    //身份证判断
    idchecked(idcard){
        if(!idcard){
            this.errmessage = '请输入身份证号码'
            return 
        }
        const patten18 =  /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
        const paten15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/
        if(patten18.test(idcard)||paten15.test(idcard)){
            this.errmessage = ''
            return
        }
        this.errmessage = '请输入正确的身份证号'

    }, 
    // 提取身份证日期
    getbirthday(idcard){
        if(!idcard){
            return 
        }
        const patten18 =  /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
        const paten15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/ 
        if((paten15.test(idcard)||patten18.test(idcard))){
            let birth = ''
            if(idcard.length===15){
                birth = '19'+idcard.substring(6,12)
            }else if(idcard.length===18){
                birth = idcard.substring(6,14)
            }
            this.jzrinfo.birthday = birth.substring(0,4)+'-'+birth.substring(4,6)+'-'+birth.substring(6,8)
        }
    },
    // 选择性别
    changesex(sex){
        if(sex==='1'){
            this.jzrinfo.sex = 1
            this.sexshow = true
        }
        if(sex==='0'){
            this.jzrinfo.sex = 2
            this.sexshow = false
        }
        console.log(this.jzrinfo.sex)
    },
    //  弹出地区选择框
    bindShow(){
        this.showArea = true
        console.log(AreaInfo.area)
    },
    bindCancel(){
        this.showArea = false
    },
    onAreaConfirm(val){
        console.log(val)
        this.showArea = false
        var addrInfo = val[0].name + val[1].name + val[2].name;
        this.jzrinfo.address = addrInfo

    },
    sure(value,index){
        this.showtype = false
        this.jzrinfo.ybtype = value
    },
    // 删除
    deljzr(){
        this.$store.dispatch('jzrgl/wxjzrdel',{id:this.$route.query.id}).then(()=>{
            console.log('1')
            this.$router.push('jzrgl')

        }).catch(()=>{
            console.log('2')
        })

    },
    },
    mounted(){
        if(this.$route.query.edit){
            const id = this.$route.query.id
            this.$store.dispatch('jzrgl/wxjzrinfo',{id:id}).then(()=>{
                this.jzrinfo = this.$store.state['jzrgl']['jzrinfo']
                this.jzrinfo.id = id
                if(this.jzrinfo.ybtype===0){
                    this.jzrinfo.ybtype='自费'
                }else{
                    this.jzrinfo.ybtype='医保'
                }
                if(this.jzrinfo.sex===1){
                    this.sexshow = true

                }
                else{
                    this.sexshow=false
                }
                if(this.jzrinfo.type===0){
                 this.defaultchange = true
                }else{
                    this.defaultchange = false
                }
               
            }).catch(()=>{
                console.log('22')
            })
        }
    }

}
</script>

<style lang="less" scoped>
.content{
    .warm{
        width: 100%;
        padding: 0.3rem;
        background: rgb(255,242,233);
        color: rgb(255,137,58);


    }
    .type{
        width: 100%;
        padding: 0.4rem;
        background: #fff;
        margin-top: 0.1rem;
        padding: 0.3rem;
        .a{
            background: rgb(245,245,245);
            display: inline-block;
            // height: 0.4rem;
            // width: 0.8rem;
            text-align: center;
            line-height: 0.4rem;
            border-radius: 0.4rem;
            font-size: 16px;
            padding: 0.1rem;
            color: rgb(94,94,94);
            margin-left: 0.2rem;       
        }
        .b{
            // height: 0.4rem;
            // width: 2.5rem;
            background: rgb(245,245,245);
            border-radius: 0.4rem;
            padding: 0.1rem;
            font-size: 16px;
            color: rgb(94,94,94);
            text-align: center;
            line-height: 0.4rem;
            display: inline-block;
            margin-left: 0.2rem;
        }
        .c{
            color: #fff;
            background: rgb(91,153,236);
        }

    }
    .info{
        margin-top: 0.1rem;
    }
}

.sex{
   width: 1.0rem;
   height: 0.3rem;
   background: rgb(245,245,245);
   text-align: center;
   line-height: 0.3rem;
   border-radius: 0.4rem;
   padding: 0.1rem;
   margin-left: 0.2rem;
   color: rgb(94,94,94);
}
 .van-field__body{
    flex-direction: row-reverse
}
.sexlist>div:first-child{
    margin-left: 0;
}

.defaultsex{
    background: rgb(91,153,236);
    color: #fff;

}

</style>