<template>
  <div>
    <div style="width: 100%;margin-bottom: 2rem; overflow: auto">
      <div class="tjinfo">
        <div class="infoitem">
          <div class="itemkey">就诊医院</div>
          <div class="itemvalue">{{ tjinfo.org_name }}</div>
        </div>
        <div class="infoitem">
          <div class="itemkey">就诊科室</div>
          <div class="itemvalue">{{ tjinfo.ks_name }}</div>
        </div>
        <div class="infoitem">
          <div class="itemkey">就诊医生</div>
          <div class="itemvalue">{{ tjinfo.name }}</div>
        </div>
        <div class="infoitem">
          <div class="itemkey">门诊类型</div>
          <div class="itemvalue">{{ tjinfo.reg_category_name }}</div>
          <div style="font-size: 20px; margin-left: 10px; color: red">
            {{ tjinfo.je }}
          </div>
        </div>
        <div class="infoitem">
          <div class="itemkey">候诊时间</div>
          <div class="itemvalue" style="color: red">{{ tjinfo.hz_time }}</div>
        </div>
      </div>
      <div class="yysm">
        <div style="position: relative">
          <van-icon
            color="rgb(255,134,133)"
            name="warning"
            size="25"
            style="position: absolute; left: -40px"
          />
          预约说明
        </div>
        <div style="color: red; font-weight: 600">
          1、挂号费必须在15分钟内支付，否则超时自动取消
        </div>
        <div style="color: red; font-weight: 600">
          2、挂号费支付完成，才算预约挂号完成
        </div>
        <div>3、如未抢到您预约的号源，将自动为您抢之后的号源</div>
        <div>4、在线支付暂不支持医保支付</div>
        <div>5、预约挂号缴费时需支付中医诊察附加费2元</div>
        <div>6、预约挂号缴费时需支付门诊医疗费物处置费0.1元</div>
      </div>
    </div>
    <div class="tjbutton">
      <van-button
        round
        style="width: 80%; font-size: 20px"
        color="rgb(66,137,255) "
        @click="tj"
        >提交</van-button
      >
    </div>
  </div>
</template>

<script>
export default {
    name:'Ghtj',
    data(){
        return{
          tjinfo:{},
          ghway:'',

        }
    },
    mounted(){
      this.gettjinfo()
      this.ghway = this.$route.query.ghway
     

    },
    methods:{
        gettjinfo(){
          this.tjinfo = JSON.parse(localStorage.getItem('tjinfo'))
          console.log(this.tjinfo)
        },
        tj(){
          if(this.ghway==='预约挂号'){
            this.$store.dispatch('yygh/clicktjyy',this.tjinfo).then(()=>{
              const zfinfo = this.$store.state['yygh']['zfinfo']
              console.log(zfinfo)
              localStorage.setItem('zfinfo',JSON.stringify(zfinfo))
              this.$router.push({path:'/drghzf',query:{ghway:'预约挂号'}})
            }).catch(()=>{
              
            })
          }else{
          this.$store.dispatch('drgh/clicktj',this.tjinfo).then(()=>{
            const zfinfo = this.$store.state['drgh']['tjinfo']
            console.log(zfinfo)
            localStorage.setItem('zfinfo',JSON.stringify(zfinfo))
            this.$router.push('/drghzf')
          }).catch(()=>{
            console.log('提交失败')
          })
        }
      }
    



    }
};
</script>

<style lang="less" scoped>
.tjinfo {
  background: #fff;
  padding: 10px 20px 20px 20px;
  .infoitem {
    display: flex;
    margin-top: 10px;
    .itemkey {
      font-size: 20px;
      color: rgb(113, 115, 129);
    }
    .itemvalue {
      margin-left: 20px;
      font-size: 20px;
      color: rgb(71, 72, 78);
    }
  }
}

.yysm {
  background: #fff;
  margin-top: 30px;
  padding: 20px 40px 40px 60px;
  font-size: 20px;
}
.tjbutton {
  width: 100%;
  height: 2rem;
  border-top: 1px solid rgb(128, 128, 128);
  position: fixed;
  bottom: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
</style>