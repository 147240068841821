<template>
  <div>
    消息
  </div>
</template>

<script>
export default {
name:'Message'
}
</script>

<style>

</style>