<template>
  <div>
    <van-cell-group>
        <div style="display: flex;height: 1rem;width: 100%;line-height: 1rem;font-size: 16px;color: rgb(100,113,146);padding: ;justify-content: space-between;">
            <div style="margin-left: 0.3rem;">头像</div>
            <img :src="userinfo.img" alt="" style="width: 1rem;height: 1rem;border-radius: 50%;margin-right: 0.3rem;">
        </div>
        <div style="padding: 0 0.3rem 0 0.3rem;">
           <div class="line" ></div>
        </div>
        <van-field @click="editname" label="用户名" readonly :value="userinfo.username" input-align="right" is-link style="font-size: 16px;"></van-field>
        <van-field input-align="right" label="定位" readonly is-link style="font-size: 16px;" @click="area" :value="userinfo.addres"></van-field>
    </van-cell-group>
    <div style="margin-top: 0.3rem;">
        <van-field label="手机号" is-link :value="userinfo.phone" input-align="right" readonly style="font-size: 16px;"></van-field>
    </div>
    <!-- 地区选择 -->
    <van-popup position="bottom" v-model="showarea" >
        <van-area :area-list="arealist" @confirm="onAreaConfirm" @cancel="bindCancel"></van-area>  
    </van-popup>
    <div  @click="quit" style="padding: 0.1rem;font-size: 20px;text-align: center;margin-top: 10px;">
        退出
    </div>

  </div>
</template>

<script>
import AreaInfo from '../../area/area'
import wx from 'weixin-js-sdk'
export default {
    data(){
        return{
            showarea:false,
            arealist:AreaInfo.area,
            userinfo:{
                img:require('@/assets/weixin.jpg'),
                username:'',
                addres:'',
                phone:'',
            },
         

        }
    },
    methods:{
        area(){
            this.showarea = true
        },
        onAreaConfirm(val){
            this.showarea = false
            this.userinfo.addres = val[0].name+val[1].name+val[2].name

        },
        bindCancel(){
            this.showarea = false

        },
        editname(){
            this.$router.push('/editusername')
        },
        quit(){
            localStorage.clear()
            wx.closeWindow()
            this.$router.push('home')
        }

    }

}
</script>

<style scoped lang="less">
.line{
    width: 100%;
    height: 1px;
    background: rgb(251,252,252);

}

</style>