import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login.js'
import drgh from './modules/drgh.js'
import yygh from './modules/yygh.js'
import jzrgl  from './modules/jzrgl.js'
Vue.use(Vuex)
const store  = new Vuex.Store({
  modules:{
    login,
    drgh,
    yygh,
    jzrgl
  }
})

export default store