import {registeyy,showdnyy,clickh,clickghyy,clicktjyy} from '@/api/yygh'

const getDefaultState = ()=>{
    return{
         ks:[],
         xhlist:[],
         clickh:{},
         tjinfo:{},
         zfinfo:{},
        
    }
}
const state = getDefaultState()

const actions = {
    registeyy({commit}){
        return new Promise((resolve,reject)=>{
            registeyy().then((response)=>{
                commit('SET_KS',response.data)
                resolve()
            }).catch(()=>{
               reject()
            })
        })

    },
    showdnyy({commit},data){
        return new Promise((resolve,reject)=>{
            showdnyy(data).then((response)=>{
                commit('SET_XHLIST',response)
                resolve()
            }).catch(()=>{
                reject()
            })

        })
    },
    clickh({commit},data){
        return new Promise((resolve,reject)=>{
            clickh(data).then((response)=>{
                console.log(response.data)
                commit('SET_CLICKH',response.data)
                console.log(response.data)
                resolve()
            }).catch(()=>{
                reject()
            })
        })
    },
    clickghyy({commit},data){
        return new Promise((resolve,reject)=>{
            clickghyy(data).then((response)=>{
                console.log(response.data)
                commit('SET_TJ',response.data)
                localStorage.setItem('tjinfo',JSON.stringify(response.data))
                resolve()
            }).catch(()=>{
                console.log()
                reject()
            })

        })
    },
    clicktjyy({commit},data){
        return new Promise((resolve,reject)=>{
          clicktjyy(data).then((response)=>{
            console.log(response.data)
            commit('SET_ZF',response.data)
            resolve()
          }).catch(()=>{
            console.log()
            reject()
          })
        })
    },
    
}

const mutations = {
    SET_KS:(state,ks)=>{
        state.ks = ks

    },
    SET_XHLIST:(state,xhlist)=>{
        state.xhlist = xhlist
    },
    SET_CLICKH:(state,clickh)=>{
        state.clickh = clickh

    },
    SET_TJ:(state,tjinfo)=>{
        state.tjinfo = tjinfo
    },
    SET_ZF:(state,zfinfo)=>{
        state.zfinfo = zfinfo
    },
 

}

export default{
    namespaced:true,
    state,
    actions,
    mutations
}